import { useClipboard } from '@pesto/hooks';
import { Button, Card, Input, Typography } from '@pesto/ui';
import { ImageGallery } from '@pesto/ui/components/ImageGallery';
import { Label } from '@pesto/ui/components/Label';
import { QrCode } from '@pesto/ui/components/QrCode';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@pesto/ui/components/Tooltip';
import { formatApplicationValues, humanize } from '@pesto/utils';
import { CopyIcon, CrossIcon, MailIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import type { Item } from '../__generated__/graphql/api';
import { AssetType, ItemEventType } from '../__generated__/graphql/api';
import { routes } from '../constants/routes';

export enum ItemActions {
  REVALUE = 'REVALUE',
  SUBMIT = 'SUBMIT',
  REJECT = 'REJECT',
  DAMAGE = 'REPORT DAMAGE',
  ADD_CUSTODY_EVENT = 'ADD CUSTODY EVENT',
  UPDATE_APPRAISAL = 'UPDATE APPRAISAL',
  OVERRIDE_CREDIT_VALUE = 'OVERRIDE CREDIT VALUE',
}

export const itemDenyReasons = [
  { value: 'REASON1', label: 'Collateral type not acceptable' },
  { value: 'REASON2', label: 'Collateral condition not acceptable' },
  { value: 'REASON3', label: 'Collateral value below minimum requirements' },
  { value: 'REASON4', label: 'Collateral value above maximum requirements' },
];

export function ItemCard({ item }: { item?: Item }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { copy } = useClipboard();

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleCopy = useCallback(
    (value: string) => {
      copy(value);
    },
    [copy],
  );

  const missingFinalAppraisal =
    (item?.itemEvents?.filter(itemEvent => itemEvent?.itemEventType === ItemEventType.FinalAppraisal)?.length ?? 0) ===
    0;
  const needsReview = item?.needsReview ?? false;

  return (
    <Card className={'bg-card w-full overflow-auto'}>
      <div className={''}>
        {(needsReview || missingFinalAppraisal) && (
          <div className="bg-destructive flex min-h-[40px] items-center justify-between border-b px-2 text-white">
            Missing:
            <div>
              {missingFinalAppraisal && <p>&#8729; Pesto Appraisal</p>}
              {needsReview && <p>&#8729; Review</p>}
            </div>
          </div>
        )}
        <div>
          <div className="flex justify-between px-4 py-1 font-medium">
            <div>
              <Typography variant={'subHeroSmall'}>{humanize(item?.subtype ?? 'Item')}</Typography>
              <Link className={'text-action'} to={`${routes.itemDetail}/${item?.id}`}>
                Manage
              </Link>
            </div>
            <QrCode data={item?.id ?? ''} size={70} />
          </div>
        </div>
        <div className="border-b">
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Id
            <div className={'flex'}>
              <div className={'text-foreground truncate'}>{formatApplicationValues('id', item?.id)}</div>
              <button onClick={() => handleCopy(item?.id ?? '')}>
                <CopyIcon />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-between border-b">
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Type
            <div className={'text-foreground'}>{formatApplicationValues('subtype', humanize(item?.type ?? ''))}</div>
          </div>
          {item?.type === AssetType.Other && (
            <div className="border-b">
              <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
                Other Type
                <div className={'text-foreground'}>{item?.otherAssetType}</div>
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full justify-between border-b">
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Valuation (Q)
            <div className={'text-foreground'}>
              {formatApplicationValues('valuation', item?.quickValuation ?? '--')}
            </div>
          </div>
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Credit Value (Q)
            <div className={'text-foreground'}>
              {formatApplicationValues('valuation', item?.quickCreditValue ?? '--')}
            </div>
          </div>
        </div>
        <div className="flex justify-between border-b">
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Valuation (F)
            <div className={'text-foreground'}>
              {formatApplicationValues('valuation', item?.finalValuation ?? '--')}
            </div>
          </div>
          <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">
            Credit Value (F)
            <div className={'text-foreground'}>
              {formatApplicationValues('valuation', item?.finalCreditValue ?? '--')}
            </div>
          </div>
        </div>

        {(item?.presignedPhotoLinks?.length ?? 0) > 0 && (
          <div className="border-b">
            <div className="text-foreground/60 whitespace-nowrap px-4 py-1 font-medium">Photos</div>
            <div className={'mb-2 ml-2'}>
              <div className="flex h-[64px] w-[64px] gap-2 rounded object-cover object-center">
                {item?.presignedPhotoLinks?.map((url, index) => {
                  if (!url) return null;
                  return (
                    <img
                      key={url + index}
                      className={'border-neutralSoft w-full rounded-full border-2 object-cover object-center'}
                      src={url || ''}
                      onClick={() => openImageViewer(index)}
                      alt={`Preview of ${item.type}`}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <ImageGallery
          isOpen={isViewerOpen}
          images={(item?.presignedPhotoLinks as string[]) ?? []}
          currentIndex={currentImage}
          onClose={closeImageViewer}
        />
        {!item?.active && (
          <div className="bg-warningSoft text-foreground flex min-h-[40px] items-center justify-center border-b px-2">
            Inactive Item
          </div>
        )}
      </div>
    </Card>
  );
}

export function OtherItemActions({
  item,
  onValuation,
  onReject,
}: {
  item: Item;
  onReject?: () => void;
  onValuation?: (newVal: number) => void;
}) {
  const [val, setVal] = useState(item?.valuation ?? 0);
  useEffect(() => {
    console.log(`${item?.id?.substring(0, 6)} : ${val}`);
  }, [val, item]);
  return (
    <div className={'m-4'}>
      <div className={'flex items-center gap-6'}>
        <TooltipProvider>
          <Tooltip>
            <TooltipContent>
              <Typography variant={'body'}>Reject Item</Typography>
            </TooltipContent>
            <TooltipTrigger>
              <Button onClick={onReject} color={'destructive'}>
                <CrossIcon />
              </Button>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>

        {/* TODO: make it like a currency input */}
        <Label htmlFor="valuation">Quick Appraisal Valuation</Label>
        <Input id="valuation" onChange={e => setVal(Number(e.target.value))} defaultValue={val === -1 ? 0 : val} />

        <TooltipProvider>
          <Tooltip>
            <TooltipContent>
              <Typography variant={'body'}>Accept Item & Send Valuation</Typography>
            </TooltipContent>
            <TooltipTrigger>
              <Button onClick={() => onValuation?.(val)}>
                <MailIcon />
              </Button>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
}
