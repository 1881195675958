import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import {
  generateBooleanFilterOptions,
  generateSelectableFilterOptions,
  setColumnFilterAndSortKey,
} from '@pesto/ui/features/SmartTable/utils/helpers';
import { formatDate } from '@pesto/utils';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Item } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus } from '../../../__generated__/graphql/api';

const columnIdNames = {
  id: setColumnFilterAndSortKey('idSearch', 'idSortDirection'),
  insertedAt: setColumnFilterAndSortKey('', 'insertedAtSortDirection'),
  images: 'images',
  active: setColumnFilterAndSortKey('active', ''),
  needsReview: setColumnFilterAndSortKey('itemEventNeedsReview', ''),
  itemEventDate: setColumnFilterAndSortKey('', 'itemEventInsertedAtSortDirection'),
  action: 'action',
  type: setColumnFilterAndSortKey('itemEventTypeSelections', 'itemEventTypeSortDirection'),
  subtype: setColumnFilterAndSortKey('itemEventSubtypeSelections', 'itemEventSubtypeSortDirection'),
  description: setColumnFilterAndSortKey('itemEventDescriptionSearch', 'itemEventDescriptionSortDirection'),
  creditApplicationStatus: setColumnFilterAndSortKey('creditApplicationStatusSelections', ''),
};

export const useItemsListConfig = () => {
  const itemsFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Item ID',
      value: columnIdNames.id,
      placeholder: 'Item ID search',
    },
    {
      label: 'Active',
      value: columnIdNames.active,
      options: generateBooleanFilterOptions(),
    },
    // {
    //   label: 'Description',
    //   value: columnIdNames.description,
    //   placeholder: 'Description search',
    // },
    {
      label: 'Needs Review',
      value: columnIdNames.needsReview,
      options: generateBooleanFilterOptions(),
    },

    {
      label: 'Credit Application Status',
      value: columnIdNames.creditApplicationStatus,
      options: generateSelectableFilterOptions(CreditApplicationStatus),
    },
  ];

  const getItemsColumns = (
    handleRowClicked: (row: Item) => void,
    handleRowRejectClicked: (row: Item) => void,
  ): ColumnDef<Item>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => {
        const showReject = row.cell.row.original.needsReview;
        return (
          <div className="flex gap-4">
            <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
              View
            </Button>

            {showReject && (
              <Button onClick={() => handleRowRejectClicked(row.cell.row.original)} color={'destructive'}>
                Reject
              </Button>
            )}
          </div>
        );
      },
    },
    {
      id: columnIdNames.images,
      header: 'Images',
      accessorFn: row => row.presignedPhotoLinks,
      cell: row => {
        const firstImage = (row.cell.getValue() as string[])?.[0];

        return (
          <img
            className={'border-primary/20 w-full rounded-md border object-cover object-center'}
            src={firstImage || ''}
            alt={`Preview of ${row.row.original.type}`}
          />
        );
      },
    },
    {
      id: columnIdNames.id,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Item ID" />,
      accessorFn: row => row.id,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },

    {
      id: columnIdNames.insertedAt,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created" />,
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.active,
      header: 'Active',
      accessorFn: row => row.active,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.needsReview,
      header: 'Needs Review',
      accessorFn: row => row.needsReview,
      cell: row => {
        const value = row.cell.getValue() as boolean;
        return value ? 'Yes' : 'No';
      },
    },
    {
      id: columnIdNames.itemEventDate,
      header: 'Item Event Date',
      accessorFn: row => row.insertedAt,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {formatDate(row.cell.getValue() as string)}
        </Typography>
      ),
    },
    {
      id: columnIdNames.type,
      header: 'Type',
      accessorFn: row => row.type,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.subtype,
      header: 'Subtype',
      accessorFn: row => row.subtype,
      cell: row => row.cell.getValue(),
    },
    {
      id: columnIdNames.description,
      header: 'Description',
      accessorFn: row => row.description,
      cell: row => {
        const value = row.cell.getValue() as string;

        return <div className="whitespace-normal">{value}</div>;
      },
    },
    {
      id: columnIdNames.creditApplicationStatus,
      header: 'Credit Application Status',
      accessorFn: row => row.creditApplication?.status,
      cell: row => row.cell.getValue(),
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    sorting: [{ id: 'insertedAtSortDirection', desc: true }],
  };

  return { itemsFilterFields, getItemsColumns, initialTableState };
};
