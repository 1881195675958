import { useAuth } from '@pesto/features';
import type { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from '../components/ProtectedRoute';
import { routes } from '../constants/routes';
import { MCApplicationLayout } from '../layouts/MCApplicationLayout';
import { AddFinalAppraisal } from '../pages/AddFinalAppraisal/AddFinalAppraisal';
import { CardDetails } from '../pages/CardDetails/CardDetails';
import { Cards } from '../pages/Cards/Cards';
import { CreditApplicationDetail } from '../pages/CreditApplicationDetail/CreditApplicationDetail';
import { CreditApplications } from '../pages/CreditApplications/CreditApplications';
import { CreditEngine } from '../pages/CreditEngine/CreditEngine';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { ItemDetails } from '../pages/ItemDetails/ItemDetails';
import { Items } from '../pages/Items/Items';
import { Login } from '../pages/Login';
import LoginCallback from '../pages/LoginCallback';
import { NotFound } from '../pages/NotFound';
import { AdTrackingList } from '../pages/Other/AdTrackingList/AdTrackingList';
import { LegalLinks } from '../pages/Other/LegalLinks/LegalLinks';
import { LookerStudio } from '../pages/Other/LookerStudio';
import { Reports } from '../pages/Other/Reports';
import { SecurityEventsList } from '../pages/Other/SecurityEventsList/SecurityEventsList';
import { Payments } from '../pages/Payments/Payments';
import { AddProgram } from '../pages/Programs/AddProgram';
import { AddProgramUser } from '../pages/Programs/AddProgramUser';
import { ProgramDetails } from '../pages/Programs/ProgramDetails';
import { Programs } from '../pages/Programs/Programs';
import { UpdateProgram } from '../pages/Programs/UpdateProgram';
import { UpdateProgramUser } from '../pages/Programs/UpdateProgramUser';
import { Statments } from '../pages/Statements/Statements';
import { StatementsDetails } from '../pages/StatementsDetails/StatementsDetails';
import { Transactions } from '../pages/Transactions/Transactions';
import { UserDetailsPage } from '../pages/UserDetails/UserDetailsPage';
import { Users } from '../pages/Users/Users';

export const AppRoutesProvider = ({ children }: { children: ReactNode }) => {
  return <MCApplicationLayout>{children}</MCApplicationLayout>;
};

export const RouterProvider = () => {
  const { isAuthenticated } = useAuth();
  return (
    <AppRoutesProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route index element={<Login />} />
        <Route path={routes.loginCallback} element={<LoginCallback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated()} />}>
          <Route path={routes.dashboard} element={<Dashboard />} />

          <Route path={routes.items} element={<Items />} />
          <Route path={`${routes.items}/:itemId`} element={<ItemDetails />} />

          <Route path={routes.creditApplications} element={<CreditApplications />} />
          <Route path={`${routes.creditApplications}/:id`} element={<CreditApplicationDetail />} />

          <Route path={routes.users} element={<Users />} />
          <Route path={`${routes.users}/:id`} element={<UserDetailsPage />} />

          <Route path={routes.programs} element={<Programs />} />
          <Route path={`${routes.programs}/:id`} element={<ProgramDetails />} />
          <Route path={`${routes.programs}/add`} element={<AddProgram />} />
          <Route path={`${routes.programs}/:id/add-user`} element={<AddProgramUser />} />
          <Route path={`${routes.programs}/:id/edit`} element={<UpdateProgram />} />
          <Route path={`${routes.programs}/:id/users/:userId/edit`} element={<UpdateProgramUser />} />

          <Route path={routes.cards} element={<Cards />} />
          <Route path={`${routes.cards}/:cardId?`} element={<CardDetails />} />

          <Route path={routes.statements} element={<Statments />} />
          <Route path={`${routes.statements}/:id?`} element={<StatementsDetails />} />

          <Route path={routes.transactions} element={<Transactions />} />

          <Route path={routes.payments} element={<Payments />} />

          <Route path={routes.creditEngine} element={<CreditEngine />} />

          <Route path={`${routes.addFinalAppraisal}/:id/`} element={<AddFinalAppraisal />} />
          <Route path={`${routes.addFinalAppraisal}/:id/:itemId`} element={<AddFinalAppraisal />} />

          {/* Other Routes */}
          <Route path={`${routes.reports}`} element={<Reports />} />
          <Route path={`${routes.legalLinks}`} element={<LegalLinks />} />
          <Route path={`${routes.lookerStudio}`} element={<LookerStudio />} />
          <Route path={routes.securityEvents} element={<SecurityEventsList />} />
          <Route path={routes.adTracking} element={<AdTrackingList />} />
        </Route>
      </Routes>
    </AppRoutesProvider>
  );
};
