import { useQuery } from '@apollo/client';
import { Button, Card, Typography } from '@pesto/ui';
import { useNavigate, useParams } from 'react-router-dom';

import type { GetProgramByIdQuery, GetProgramByIdQueryVariables } from '../../__generated__/graphql/api';
import { GetProgramByIdDocument } from '../../__generated__/graphql/api';

export const ProgramDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<GetProgramByIdQuery, GetProgramByIdQueryVariables>(GetProgramByIdDocument, {
    variables: { id: id! },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const program = data?.program;

  if (!program) return <p>Program not found</p>;

  const handleAddUser = () => {
    navigate(`/programs/${id}/add-user`);
  };

  const handleEditProgram = () => {
    navigate(`/programs/${id}/edit`);
  };

  const handleEditUser = (userId: string) => {
    navigate(`/programs/${id}/users/${userId}/edit`);
  };

  return (
    <>
      <div className={'flex flex-col gap-6'}>
        <Typography variant={'headerLarge'}>Program Details</Typography>
      </div>
      <div className="p-4">
        <Card>
          <Typography variant="headerLarge">{program.name}</Typography>
          <Typography variant="body">ID: {program.id}</Typography>
          <Typography variant="body">Card Program: {program.cardProgram}</Typography>
          <Typography variant="body">
            Collateral Type: {program.collateralType?.replace(/_/g, ' ') ?? 'None Selected'}
          </Typography>
          <Button variant="primary" onClick={handleEditProgram}>
            Edit Program
          </Button>
          <Typography variant="headerMedium" className="mt-4">
            Users
          </Typography>
          {program.users?.length ? (
            <ul>
              {program.users.map(user => (
                <li key={user?.id ?? 'unknown'}>
                  <Typography variant="body">
                    {user?.firstName ?? 'Unknown'} {user?.lastName ?? 'User'} - {user?.email ?? 'No email'}
                  </Typography>
                  <Button variant="outline" onClick={() => handleEditUser(user?.id ?? '')}>
                    Edit User
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body">No users found</Typography>
          )}
        </Card>
        <div className="mt-4 flex gap-4">
          <Button variant="outline" onClick={() => window.history.back()}>
            Back
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </div>
      </div>
    </>
  );
};
