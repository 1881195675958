import type { WatchQueryFetchPolicy } from '@apollo/client';

import {
  CollateralType,
  CreditApplicationStatus,
  PaymentPreference,
  useListCardsQuery,
  useListCreditApplicationsQuery,
  usePaymentStatsQuery,
  useStatsQuery,
} from '../../../__generated__/graphql/api';
import { apolloClient } from '../../../providers/Apollo';

export const openPaymentsStatuses = [
  CreditApplicationStatus.Prequalified,
  CreditApplicationStatus.PrequalInfoGiven,
  CreditApplicationStatus.ManualReview,
  CreditApplicationStatus.OfferDecisionNeeded,
];

export const useDashboardData = () => {
  const baseStatsQueryOptions = {
    errorPolicy: 'all' as const,
    client: apolloClient,
    fetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
  };

  const { data } = useListCardsQuery({
    ...baseStatsQueryOptions,
    variables: {
      userIsFriendsAndFam: false,
      active: true,
    },
  });
  const cardsIssuedCount = data?.listCards?.pagination?.totalRecords || 0;

  const { data: manualStatsItems } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ManualReview],
      collateralTypeSelections: [CollateralType.Item],
    },
  });
  const { data: manualStatsCash } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ManualReview],
      collateralTypeSelections: [CollateralType.Cash],
    },
  });

  const { data: reviewNeededStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.ItemUnderReview],
    },
  });
  const { data: completeStats, loading: loadingCompleteStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.Complete],
    },
  });
  const { data: inProgressStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.AppraisalInProgress],
    },
  });
  const { data: prequalifiedStats } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.Prequalified],
    },
  });
  const { data: offerDecisionNeeded } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.OfferDecisionNeeded],
    },
  });
  const { data: offerRejected } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.OfferRejected],
    },
  });

  const { data: verifyDoc } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.VerifyDoc],
    },
  });
  const { data: verifyPayment } = useStatsQuery({
    ...baseStatsQueryOptions,
    variables: {
      statusSelections: [CreditApplicationStatus.VerifyPayment],
    },
  });

  const appsNeedingOutboundShippingKitLabelCreated =
    useListCreditApplicationsQuery({
      errorPolicy: 'all',
      client: apolloClient,
      fetchPolicy: 'cache-and-network',
      variables: {
        paginationDetailsOnly: true,
        userIsFriendsAndFam: false,
      },
    })?.data?.listCreditApplications?.pagination?.totalRecords || 0;

  const appsNeedingOutboundShippingKitLabelSent =
    useListCreditApplicationsQuery({
      errorPolicy: 'all',
      client: apolloClient,
      fetchPolicy: 'cache-and-network',
      variables: {
        paginationDetailsOnly: true,
        userIsFriendsAndFam: false,
      },
    })?.data?.listCreditApplications?.pagination?.totalRecords || 0;

  const { data: payments } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
    },
  });

  const { data: payNow } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
      paymentPreferenceSelections: PaymentPreference.PayInFull,
    },
  });
  const { data: payOverTime } = usePaymentStatsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    variables: {
      statusSelections: openPaymentsStatuses,
      paymentPreferenceSelections: PaymentPreference.PayOverTime,
    },
  });

  return {
    offerRejected,
    verifyDoc,
    verifyPayment,
    offerDecisionNeeded,
    prequalifiedStats,
    inProgressStats,
    completeStats,
    loadingCompleteStats,
    reviewNeededStats,
    manualStatsItems,
    manualStatsCash,
    payments,
    payNow,
    payOverTime,
    appsNeedingOutboundShippingKitLabelSent,
    appsNeedingOutboundShippingKitLabelCreated,
    cardsIssuedCount,
  };
};
