import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@pesto/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CollateralType, useCreateProgramMutation } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

export const AddProgram = () => {
  const navigate = useNavigate();
  const [createProgram, { loading, error }] = useCreateProgramMutation();
  const [formData, setFormData] = useState({
    name: '',
    cardProgram: '',
    collateralType: CollateralType.NoneSelected,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string, selectName?: string) => {
    if (typeof e === 'string' && selectName) {
      setFormData({ ...formData, [selectName]: e });
    } else if (typeof e !== 'string') {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createProgram({ variables: { input: formData } });
      navigate(routes.programs);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="p-4">
      <h1 className="mb-4 text-xl font-bold">Add Program</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
          {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="cardProgram" className="block text-sm font-medium text-gray-700">
            Card Program
          </label>
          <Input
            id="cardProgram"
            name="cardProgram"
            value={formData.cardProgram}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
          {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="collateralType" className="block text-sm font-medium text-gray-700">
            Collateral Type
          </label>
          <Select
            value={formData.collateralType}
            onValueChange={(value: CollateralType) => setFormData({ ...formData, collateralType: value })}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select collateral type" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(CollateralType).map(type => (
                <SelectItem key={type} value={type}>
                  {type.replace(/_/g, ' ')}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
        </div>
        <Button type="submit" variant="primary" loading={loading}>
          Add Program
        </Button>
      </form>
    </div>
  );
};
