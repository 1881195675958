import { useMutation, useQuery } from '@apollo/client';
import { Button, Input } from '@pesto/ui';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GetProgramByIdDocument, UpdateProgramDocument } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

export const UpdateProgramUser = () => {
  const { id, userId } = useParams<{ id: string; userId: string }>();
  const navigate = useNavigate();
  const { data, loading: queryLoading } = useQuery(GetProgramByIdDocument, { variables: { id: id! } });
  console.log(data);
  const [updateProgramUser, { loading: mutationLoading, error }] = useMutation(UpdateProgramDocument);
  const [formData, setFormData] = useState({
    firstName: data?.user?.firstName || '',
    lastName: data?.user?.lastName || '',
    email: data?.user?.email || '',
    phone: data?.user?.phone || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProgramUser({ variables: { id: userId!, input: formData } });
      navigate(`${routes.programs}/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  if (queryLoading) return <p>Loading...</p>;

  return (
    <div className="p-4">
      <h1 className="mb-4 text-xl font-bold">Update Program User</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <Input
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <Input
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <Input
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          <Input
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <Button type="submit" variant="primary" loading={mutationLoading}>
          Update User
        </Button>
        <Button variant="outline" onClick={() => window.history.back()}>
          Cancel
        </Button>
        {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
      </form>
    </div>
  );
};
