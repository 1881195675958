import { Button, LoadingSpinner, Typography } from '@pesto/ui';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { TextField } from '@pesto/ui/components/Forms/TextField';

import type { Address } from '../../../__generated__/graphql/api';
import { STATES } from '../../../constants/States';

interface EditableAddressProps {
  formValues: Address;
  setFormValues: (field: any, value: any) => void;
  editable?: boolean;
  onEdit?: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  formErrors: any;
  isLoading: boolean;
}

export const EditableAddress = (props: EditableAddressProps) => {
  const { formValues, setFormValues, editable, onSubmit, isLoading = false, onCancel, onEdit, formErrors } = props;
  const handleCancel = () => {
    onCancel();
  };
  const handleSave = () => {
    onSubmit();
  };

  return (
    <div>
      <form className={'relative flex flex-col gap-4'} onSubmit={handleSave}>
        {isLoading && (
          <div
            className={'absolute z-[1] flex h-full w-full items-center justify-center rounded bg-gray-300 opacity-50'}
          >
            <LoadingSpinner />
          </div>
        )}
        <TextField
          onChange={event => setFormValues('addressFirstLine', event.target.value)}
          value={formValues?.addressFirstLine || ''}
          label={'Address first line'}
          readOnly={!editable}
          disabled={!editable}
        />
        <TextField
          onChange={event => setFormValues('addressSecondLine', event.target.value)}
          value={formValues?.addressSecondLine || ''}
          label={'Address second line'}
          readOnly={!editable}
          disabled={!editable}
        />
        <TextField
          onChange={event => setFormValues('city', event.target.value)}
          value={formValues?.city || ''}
          label={'City'}
          readOnly={!editable}
          disabled={!editable}
        />

        <DropdownInput
          options={STATES}
          label={'State'}
          value={formValues?.state || ''}
          placeholder={'Select state'}
          onChange={(state: string) => {
            setFormValues('state', state);
          }}
          readOnly={!editable}
          disabled={!editable}
          // isSearchable
        />
        <TextField
          onChange={event => setFormValues('zip', event.target.value)}
          value={formValues?.zip || ''}
          label={'Zip'}
          readOnly={!editable}
          disabled={!editable}
        />

        <div className={'flex items-center gap-4 pl-4'}>
          <>
            {editable ? (
              <Button onClick={handleSave} disabled={isLoading}>
                Save
              </Button>
            ) : (
              <Button onClick={onEdit}>Edit Address</Button>
            )}
            {editable && (
              <Button variant={'outline'} onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </>
        </div>
        {formErrors &&
          Object.keys(formErrors).map((key: string) => {
            return (
              <Typography variant={'body'} className={'text-danger'} key={key}>
                {formErrors[key as keyof FormData]?.message}
              </Typography>
            );
          })}
      </form>
    </div>
  );
};
