import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { GetUserByIdDocument, useAddAddressMutation, type Address } from '../../../__generated__/graphql/api';

import { EditableAddress } from './EditableAddress';
import { UserDetailsCard } from './UserDetailsCard';

interface ConnectedAddressFormProps {
  className?: string;
  title?: string;
  address?: Address | null;
  addressType: 'shipping' | 'billing' | 'payment';
  userId?: string | null;
}

const addressSchema = z.object({
  addressFirstLine: z
    .string()
    .max(35, 'Address cannot be more than 35 characters')
    .min(1, 'Address first line is required'),
  addressSecondLine: z.string(),
  city: z.string().min(1, 'City is required'),
  state: z
    .string()
    .min(1, 'State is required')
    .refine(value => value !== 'IL', {
      message: 'We currently do not operate in this state',
    }),
  zip: z
    .string()
    .min(1, 'Zip code is required')
    .refine(value => value.length === 5, {
      message: 'Please enter a valid zip code',
    }),
});

export const ConnectedAddressForm = (props: ConnectedAddressFormProps) => {
  const { title = 'AShipping Address', address, addressType, userId } = props;
  const [editMode, setEditMode] = useState(false);
  const {
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(addressSchema),
    defaultValues: {
      addressFirstLine: address?.addressFirstLine || '',
      addressSecondLine: address?.addressSecondLine || '',
      city: address?.city || '',
      state: address?.state || '',
      zip: address?.zip || '',
    },
  });

  const [addAddress, { loading }] = useAddAddressMutation({
    errorPolicy: 'all',
    refetchQueries: [{ query: GetUserByIdDocument, variables: { userId: userId || '' } }],
  });

  const formData = watch();
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleCancel = () => {
    setEditMode(false);
    reset({
      addressFirstLine: address?.addressFirstLine || '',
      addressSecondLine: address?.addressSecondLine || '',
      city: address?.city || '',
      state: address?.state || '',
      zip: address?.zip || '',
    });
  };

  const handleSubmitForm = handleSubmit(() => {
    addAddress({
      variables: {
        userId: userId || '',
        addressInput: {
          addressFirstLine: formData.addressFirstLine,
          addressSecondLine: formData.addressSecondLine,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          isShipping: addressType === 'shipping',
          isBilling: addressType === 'billing',
        },
      },
    })
      .then(response => {
        if (response.errors) {
          handleCancel();
          return console.error('Error submitting address', response.errors);
        }
        setEditMode(false);
      })
      .catch(error => {
        handleCancel();
        console.error('Error submitting address', error);
      });
  });

  if (!address) return null;
  return (
    <UserDetailsCard className={'min-w-[350px]'} title={title}>
      <EditableAddress
        formValues={formData}
        setFormValues={setValue}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onSubmit={handleSubmitForm}
        editable={editMode}
        formErrors={errors}
        isLoading={loading}
      />
    </UserDetailsCard>
  );
};
