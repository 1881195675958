import type { ReactNode } from 'react';

import { Card } from '../Card';
import { Skeleton } from '../Skeleton';
import { Typography } from '../Typography';

export interface StatBoxProps {
  title: string;
  subtitle: string;
  icon?: ReactNode;
  progress?: number;
  increase?: string;
  onClick?: () => void;
  backgroundColor?: string;
  loading?: boolean;
}

const StatBox = ({ title, subtitle, increase, onClick, loading, ...rest }: StatBoxProps) => {
  return (
    <Card
      onClick={event => {
        event.preventDefault();
        onClick?.();
      }}
      className={'hover:bg-muted/50 w-auto p-4 hover:cursor-pointer'}
      {...rest}
    >
      <Typography variant={'bodyLarge'}>{subtitle}</Typography>
      <Typography variant={'headerLarge'}>{loading ? <Skeleton className="h-8 w-16" /> : title}</Typography>
      <Typography variant={'body'}>{increase}</Typography>

      <div className="text-basic text-sm font-medium">View</div>
    </Card>
  );
};
export default StatBox;
