import { useListQuery } from '@pesto/hooks';
import { Button } from '@pesto/ui';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import type { Program } from '../../__generated__/graphql/api';
import { useListProgramsQuery } from '../../__generated__/graphql/api';
// Ensure this query hook exists
import { routes } from '../../constants/routes';

import { useProgramsListConfig } from './hooks/useProgramsListConfig';

export const Programs = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListProgramsQuery,
  });
  const { programsFilterFields, getProgramsColumns, initialTableState } = useProgramsListConfig();

  const rows = data?.programs || [];

  const handleRowClicked = (row: Program) => {
    navigate(`${routes.programs}/${row?.id}`);
  };
  const handleAddProgram = () => {
    navigate(`${routes.programs}/add`);
  };
  const columns = getProgramsColumns(handleRowClicked);

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-xl font-bold">Programs</h1>
        <Button onClick={handleAddProgram} variant="primary">
          Add Program
        </Button>
      </div>
      <SmartTable
        title="Programs"
        rows={rows}
        columns={columns}
        filterFields={programsFilterFields}
        loading={loadingOrReFetching}
        initialState={initialTableState}
      />
    </div>
  );
};
