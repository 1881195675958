import type { ChangeEvent, ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { Input } from '../Input';

import { TextFieldWrapper } from './TextFieldWrapper';
import type { TextFieldProps } from './types/Input.types';

interface CurrencyFormatProps extends Omit<TextFieldProps, 'onChange'> {
  dollarsOnly?: boolean;
  allowZero?: boolean;
  showCurrencySymbol?: boolean;
  onChange?: (value: number) => void;
}

export const CurrencyField = forwardRef((props: CurrencyFormatProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    className,
    inputClassName,
    onChange,
    label,
    placeholder,
    value,
    error,
    readOnly = false,
    showCurrencySymbol = false,
    ...inputProps
  } = props;

  const formatCurrency = (value: number) => {
    if (!value && !(value === 0 && props.allowZero)) return '';

    const formatted = (value / 100).toLocaleString('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const numberPart = props.dollarsOnly ? formatted.slice(0, -3) : formatted;
    return showCurrencySymbol ? `$${numberPart}` : numberPart;
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '-') {
      onChange?.(0);
      return;
    }

    const numberValue = parseInt(event.target.value.replace(/[^-?\d]/g, ''));
    const newValue = props.dollarsOnly ? numberValue * 100 : numberValue;

    if (!isNaN(newValue)) {
      onChange?.(newValue);
    }
  };

  return (
    <TextFieldWrapper className={className} error={error} label={label} readOnly={readOnly}>
      <Input
        ref={ref}
        type="text"
        inputMode="numeric"
        // pattern="[0-9]*"
        className={inputClassName}
        onChange={onChangeHandler}
        placeholder={placeholder}
        value={formatCurrency(typeof value === 'number' ? value : 0)}
        readOnly={readOnly}
        {...inputProps}
      />
    </TextFieldWrapper>
  );
});
